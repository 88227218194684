import React, { useState, useCallback, useEffect, Fragment } from "react";
import { Unity, useUnityContext,  } from "react-unity-webgl";
import { levels, leaderBoardAllData, itemConfigData } from "./data";
import Actions from "./components/actions";
import { Button } from "./components/ui/button"


function App() {
  function handleCacheControl(url) {
    if (url.match(/\.br/) || url.match(/\.bundle/) || url.match(/\.data/) || url.match(/\.wasm/)) {
      const sessionKey = `unity_session_id_${url}`;
      const expiryTime = 60 * 60 * 1000;  
      const sessionTime = Number(sessionStorage.getItem(sessionKey));
      if (!sessionTime) {
        sessionStorage.setItem(sessionKey, Date.now().toString());
        console.log("New session detected.");
        return "must-revalidate";
      } else {
        console.log("Same session detected.");
        const now = Date.now();
        const isExpired = now - sessionTime > expiryTime;
    
        if (isExpired) {
          sessionStorage.setItem(sessionKey, now.toString());
          console.log("Cache expired, revalidating.");
          return "must-revalidate";
        }

        return "immutable";
      }
    }

    return "no-store";
  }

  const { unityProvider, sendMessage, addEventListener, removeEventListener, isLoaded, loadingProgression  } =
    useUnityContext({
      loaderUrl: "Build/Build.loader.js",
      dataUrl: "Build/Build.data.br",
      frameworkUrl: "Build/Build.framework.js.br",
      codeUrl: "Build/Build.wasm.br",
      streamingAssetsUrl: "StreamingAssets",
      cacheControl: handleCacheControl,
    });

  const [walletInfo, setWalletInfo] = useState(null);
  const [coins, setCoins] = useState(0);

  const handleGetWalletInfo = useCallback((payload) => {
    setWalletInfo(JSON.parse(payload));
  }, []);

  const handleGetLeaderBoardAll = () => {
    sendMessage("ReactController", "OnGetLeaderBoardAll", JSON.stringify(leaderBoardAllData));
  };
  
  const handleGetItemConfig = () => {
    sendMessage("ReactController", "OnGetItemConfig", JSON.stringify(itemConfigData));
  };

  const handleUseCoin = () => {
    sendMessage("ReactController", "OnUseCoin", JSON.stringify({
      eventType: 'useCoin',
      eventData: {
        msg: 'ok',
        remaining_revive_coins: setCoins(coins - 1),
      },
    }));
  };
  
  const handlePay = (payload) => {
    const data = JSON.parse(payload);
    const additionalCoins = parseInt(data.eventData.amount_coins);
    setCoins((prevCoins) => prevCoins + additionalCoins);

    sendMessage("ReactController", "OnPay",JSON.stringify({
      eventType: 'pay',
      eventData: {
        msg: 'MINTED',
      },
    }));
    handleUseCoin();
  };

  useEffect(() => {
    addEventListener("OnGetWalletInfo", handleGetWalletInfo);
    return () => {
      removeEventListener("OnGetWalletInfo", handleGetWalletInfo);
    };
  }, [addEventListener, removeEventListener, handleGetWalletInfo]);

useEffect(() => {
  addEventListener("OnGetLeaderBoardAll", handleGetLeaderBoardAll);
  return () => {
    removeEventListener("OnGetLeaderBoardAll", handleGetLeaderBoardAll);
  };
}, [addEventListener, removeEventListener, handleGetLeaderBoardAll]);

useEffect(() => {
  addEventListener("OnGetItemConfig", handleGetItemConfig);
  return () => {
    removeEventListener("OnGetItemConfig", handleGetItemConfig);
  };
}, [addEventListener, removeEventListener, handleGetItemConfig]);

useEffect(() => {
  addEventListener("OnUseCoin", handleUseCoin);
  return () => {
    removeEventListener("OnUseCoin", handleUseCoin);
  };
}, [addEventListener, removeEventListener, handleUseCoin]);

useEffect(() => {
  addEventListener("OnPay", handlePay);
  return () => {
    removeEventListener("OnPay", handlePay);
  };
}, [addEventListener, removeEventListener, handlePay]);

useEffect(() => {
  if (coins >= 0) {
    getUserInfo();
  }
}, [coins]);

  const getUserInfo = () => {
    sendMessage("ReactController", "OnGetUserInfo", JSON.stringify({
  "eventType": "getUserInfo",
  "eventData": {
    "_id": "66dd1584975f9152cf178f5f",
    "username": "Cook",
    "address": "2mjPvsbZdyrvfXi5WX1nNw6QhQskoKqW5QbZzd8FFSpusv2Hpd",
    "remaining_revive_coins": coins,
    "level_last_completed_index": 14,
   "levels": levels,
    "created_at": "2024-09-08T03:09:42.099Z",
    "__v": 0,
    "uid": "66dd1584975f9152cf178f5f"
  }
}));
  };

  const unlock = (index) => {
    sendMessage("ReactController", "OnGetUserInfo", JSON.stringify({
  "eventType": "getUserInfo",
  "eventData": {
    "_id": "66dd1584975f9152cf178f5f",
    "username": "Cook",
    "address": "2mjPvsbZdyrvfXi5WX1nNw6QhQskoKqW5QbZzd8FFSpusv2Hpd",
    "remaining_revive_coins": coins,
    "level_last_completed_index": index,   
    "levels": levels,
    "created_at": "2024-09-08T03:09:42.099Z",
    "__v": 0,
    "uid": "66dd1584975f9152cf178f5f"
  }
}));
  };

  const connectWallet = () => {
    sendMessage("ReactController", "OnConnectWallet", JSON.stringify({
      eventData: {
        msg: "ok"
      }
    }));
  };

  const loadingPercentage = Math.round(loadingProgression * 100);

  return (
    <Fragment>
      {isLoaded == false && (
        <div className="loading-overlay">
          <p>Loading... {loadingPercentage}</p>
        </div>
      )}
      <div style={{position: 'fixed', left: 10, bottom: 10}}>
        <Actions>
            <Button onClick={getUserInfo}>获取用户信息</Button>
            <Button onClick={() => unlock(1)}>Unlock 1</Button>
            <Button onClick={() => unlock(3)}>Unlock 3</Button>
            <Button onClick={() => unlock(6)}>Unlock 6</Button>
            <Button onClick={() => unlock(9)}>Unlock 9</Button>
            <Button onClick={() => unlock(12)}>Unlock 12</Button>
            <Button onClick={connectWallet}>登录钱包</Button>
        </Actions>
      </div>
      <Unity unityProvider={unityProvider} style={{width: '100%', height: '100vh'}} />
    </Fragment>
  );
}

export default App;
